<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>联盟商家</el-breadcrumb-item>
          <el-breadcrumb-item>商品审核</el-breadcrumb-item>
          <el-breadcrumb-item>商品列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部()" name="first">
          <list isAudit="" @get-batch="getBatch" v-if="firstIs"></list>
        </el-tab-pane>
        <el-tab-pane label="待审核()" name="second">
          <list isAudit="0" @get-batch="getBatch" v-if="secondIs"></list>
        </el-tab-pane>
        <el-tab-pane label="审核不通过()" name="third">
          <list isAudit="2" @get-batch="getBatch" v-if="thirdIs"></list>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import list from "@/components/admin/merchant/audit/goods.vue";

export default {
  components: {
    list,
  },
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      activeName: "first",
      /*定义前一个活动Tab,初始化默认为 ActiveTab */
      oldTab: "add",
      /*定义Tab是否加载-第一个默认加载 */
      firstIs: true,
      secondIs: false,
      thirdIs: false,
      dialogSendVisible: false,
    };
  },
  methods: {
    handleClick(tab) {
      this[tab.name + "Is"] = true;
      if (tab.name != this.oldTab) {
        this[this.oldTab + "Is"] = false;
        this.oldTab = tab.name;
      }
    },
    getBatch(val) {
      this.show = val.bool;
      this.data = val.data;
    },
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right {
//     width: 14%;
// }

.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}

.el-image__inner {
  height: 50px !important;
}
</style>
