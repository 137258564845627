<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="申请时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              clearable
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button type="warning" @click="batchSend" icon="el-icon-finished"
              >批量审核</el-button
            >
          </el-col>
          <el-col :span="12">
            <el-button
              type="primary"
              @click="exportData"
              disabled
              icon="el-icon-tickets"
              >导出数据</el-button
            >
          </el-col>
        </el-row>
        <div></div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="编号" width="70px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="businessId"
        label="商品图片"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            :src="scope.row.commodityImgUrl"
            style="width: 50px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="shopCategoryName"
        label="类别"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="givingPercent"
        label="赠豆比例"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="preferentialPrice"
        label="折后价"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="申请时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="stateText"
        label="状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="auditTime"
        label="审核时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="auditRemake"
        label="说明"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="id" label="操作" fixed="right" width="100px">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="8">
              <el-button
                type="success"
                icon="el-icon-edit"
                plain
                @click="showDialog(scope.row)"
                >审核
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <el-dialog
      title="商品审核"
      :visible.sync="dialogSendVisible"
      width="22%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item label="不通过理由" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入不通过理由"
            v-model="form.auditRemake"
            clearable
            maxlength="250"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="success('form', 2)">审核不通过</el-button>
        <el-button type="primary" @click="success('form', 1)"
          >审核通过</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import cancel from "@/components/admin/mall/order/cancel.vue";
// import send from "@/components/admin/mall/order/send.vue";

export default {
  // components: {
  //     cancel,
  //     send,
  // },
  props: {
    isAudit: String,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 1,
      row: "",
      selectId: "",
      form: {
        isAudit: 0,
        auditRemake: "",
        shopCommodityId: "",
        shopId: "",
      },
      where: {
        pageNum: 0,
        pageSize: 0,
        isAudit: "",
        shopId: "",
      },
      dialogCancelVisible: false,
      dialogSendVisible: false,
    };
  },
  // watch: {
  //   dialogSendVisible(newName, oldName) {
  //     console.log(newName, oldName);
  //   },
  // },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.limit = this.pageSize;
      this.where.shopId = this.$route.query.id;
      this.where.isAudit = this.isAudit;
      this.where.pageNum = this.currentPage;
      this.$post(this.$api.commodityApproveDetailList, this.where).then(
        (res) => {
          if (res.code == 1) {
            this.totalData = res.data.total;
            this.list = res.data.records;
          }
        }
      );
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startTime = '';
          this.where.endTime = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    success(formName, val) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //state 1:通过；2：不通过
          if (val == 2 && this.form.remark == "") {
            this.$message({
              type: "error",
              message: "请填写不通过理由!",
            });
            return;
          }
          const data = {
            isAudit: this.form.isAudit,
            auditRemake: this.form.auditRemake,
            shopCommodityId: this.form.shopCommodityId,
            shopId: this.form.shopId,
          };
          this.post(data);
          this.dialogSendVisible = false;
        }
      });
    },
    post(data) {
      this.$post(this.$api.commodityApprove, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
    },
    batchSend() {
      if (this.$isEmpty(this.selectId)) {
        return this.$message.error("请先选择操作对象");
      }
    },
    showDialog(row) {
      this.form.shopCommodityId = row.shopCommodityId;
      this.form.shopId = row.shopId;
      this.dialogSendVisible = true;
    },
  },
  created() {
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right {
//     /*width: 14%;*/
// }

.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}
</style>


